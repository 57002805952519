<template>
  <div>
    <v-form v-model="validBatchID" v-on:submit.prevent="onSubmit">
      <v-container>
        <v-card class="ma-3">
          <v-container>
            <v-row>
              <v-col cols="2">
                <v-text-field
                  label="Batch ID"
                  autofocus
                  spellcheck="false"
                  v-model="importBatchID"
                  :rules="batchIDRules"
                  @input="clearStores"
                  persistent-hint
                  class="mb-4"
                ></v-text-field>
              </v-col>
              <v-col col="1">
                <v-btn
                  :disabled="!validBatchID"
                  class="mt-2"
                  color="primary darken-1"
                  text
                  icon
                  @click="getData"
                >
                  <v-icon>mdi-magnify</v-icon></v-btn
                >
                <v-btn
                  class="text-none mt-2 ml-6 text-white"
                  color="red darken-4"
                  variant="flat"
                  @click="OnRemoveBatchClick"
                  v-if="showDeleteButton()"
                >
                  Delete batch {{ importBatchID }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-container>
    </v-form>
    <v-container>
      <v-row>
        <v-col cols="12">
          <PubhubControl
            :displayColumns="pubhubHeaders"
            :showFilter="false"
            :showBatchLink="false"
          >
          </PubhubControl>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="12">
          <BatchInfoCard database="ETL-1"> </BatchInfoCard>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <BatchInfoCard database="ETL-2"> </BatchInfoCard>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <BatchInfoCard database="DB-1"> </BatchInfoCard>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <BatchInfoCard database="RETAIL-1"> </BatchInfoCard>
        </v-col>
      </v-row>
    </v-container>
    <confirm ref="confirm"></confirm>
  </div>
</template>

<script>
import PubhubControl, {
  ID_HEADER,
  SOURCE_HEADER,
  TYPE_HEADER,
  KEY_HEADER,
  DOWNLOAD_HEADER,
  PUBLISHED_DATE_HEADER,
  BATCH_ID_HEADER,
  ACTIONS_HEADER,
} from "@/components/pubhub/PubhubControl";
import BatchInfoCard from "@/components/batch/BatchInfoCard2";
import confirm from "@/components/utils/Confirm";

export default {
  components: {
    PubhubControl,
    BatchInfoCard,
    confirm,
  },

  created() {
    this.pubhubHeaders = [
      ID_HEADER,
      SOURCE_HEADER,
      TYPE_HEADER,
      KEY_HEADER,
      DOWNLOAD_HEADER,
      PUBLISHED_DATE_HEADER,
      BATCH_ID_HEADER,
      ACTIONS_HEADER,
    ];
  },

  data() {
    return {
      validBatchID: false,
      importBatchID: "",
      search: false,
      batchIDRules: [
        (v) => /^\d+-\d+$/.test(v.trim()) || "Value must be ##-###",
      ],
    };
  },

  methods: {
    getPubhubData() {
      let params = {
        importBatchID: this.importBatchID,
        fromDate: "1 year",
      };

      this.$store.dispatch("pubhub/getCatalog", params);
    },

    onSubmit() {
      if (this.validBatchID) {
        this.getData();
      }
    },

    showDeleteButton() {
      return (
        this.$store.getters["pubhub/getCatalog"].length > 0 ||
        this.$store.getters["etl_1/getBatches"].length > 0 ||
        this.$store.getters["etl_2/getBatches"].length > 0 ||
        this.$store.getters["db_1/getBatches"].length > 0 ||
        this.$store.getters["retail_1/getBatches"].length > 0
      );
    },

    getBatchInfo() {
      let params = {
        import_batch_id: this.importBatchID,
      };

      this.$store.dispatch("etl_1/getBatchInfo", params);
      this.$store.dispatch("etl_2/getBatchInfo", params);
      this.$store.dispatch("db_1/getBatchInfo", params);

      this.$store.dispatch("retail_1/getBatchInfo", params);
    },

    getData() {
      this.$store.dispatch("notification/clear");
      localStorage.setItem("last_batch_search", this.importBatchID);
      this.getPubhubData();
      this.getBatchInfo();
    },

    OnRemoveBatchClick() {
      this.$refs.confirm
        .open(
          "Delete",
          `Are you sure want to remove: ${this.importBatchID}? This will hard delete the batch in ALL databases.`,
          {
            color: "#C62828",
            width: 500,
          }
        )
        .then((confirm) => {
          if (confirm) {
            this.$eventHub.$emit("remove-batch", this.importBatchID);
            this.$store.dispatch("pubhub/removeImportBatch", {
              import_batch_id: this.importBatchID,
            });
          }
        });
    },

    clearStores() {
      this.$store.dispatch("pubhub/clearCatalog");
      this.$store.dispatch("etl_1/clearBatchInfo");
      this.$store.dispatch("etl_2/clearBatchInfo");
      this.$store.dispatch("db_1/clearBatchInfo");
      this.$store.dispatch("retail_1/clearBatchInfo");
      this.$store.dispatch("spdb_1/clearBatchInfo");
      this.$store.dispatch("fsdb_1/clearBatchInfo");
    },
  },

  mounted() {
    this.importBatchID = this.$route.params.id;
    if (this.importBatchID) {
      this.getData();
    } else {
      this.importBatchID = localStorage.getItem("last_batch_search");
    }
  },

  destroyed() {
    this.clearStores();
  },
};
</script>

<style></style>
